import dateFormat from "dateformat";
import Handlebars from "handlebars";
import html2pdf from "html2pdf.js";
// import data from './PDF/data'
import $ from "jquery";
import { colors, React, styled, useEffect, useState } from "../imports";
import StyledButton from "./StyledButton";
/**
 * @author Sandro Mennel
 */

Handlebars.registerHelper("getSum", (arr) =>
  arr
    .reduce((a, b) => a + parseFloat(b.field5.replace(",", ".")), 0)
    .toFixed(2)
    .toString()
    .replace(".", ",")
);
Handlebars.registerHelper("if_eq", function (a, b, opts) {
  if (a == b) {
    return opts.fn(this);
  } else {
    return opts.inverse(this);
  }
});

let data2;
let amountSum;
const OrderComplete = ({ order, color }) => {
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let orderTmp = JSON.stringify(order.lists);
    data2 = JSON.parse(orderTmp);

    if (data2.lists[0].name === "initiator") {
      data2.lists[0].name = "Meine Bestellung";
    }

    let sum = 0;
    data2.lists.map((list, l) => {
      data2.lists[l].total = data2.lists[l].total.toLocaleString("DE-DE", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      if (data2.lists[l].name === "other") {
        data2.lists[l].name = "Sonstige";
      }
      list.orderers.map((orderer, o) => {
        data2.lists[l].orderers[o].total = data2.lists[l].orderers[
          o
        ].total.toLocaleString("DE-DE", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });

        orderer.products.map((product, p) => {
          data2.lists[l].orderers[o].products[p].total = data2.lists[
            l
          ].orderers[o].products[p].total.toLocaleString("DE-DE", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });

          sum += product.product.weight * product.amount;
        });
      });
    });

    amountSum = sum;
    setShowButton(true);
  }, []);

  const ExportOrder = async (e) => {
    const coolbox = order?.shippingLines?.nodes?.find(
      (ele) => ele.methodTitle === "Kühlboxversand"
    );
    const coolboxPrice =
      parseFloat(coolbox?.total?.replace(",", ".")) +
      parseFloat(coolbox?.totalTax?.replace(",", "."));
    await $.get(
      "../../templates/report.html",
      async (html) => {
        const template = await Handlebars.compile(html);
        const htmlString = await template({
          logoUrl: `https://media.macphun.com/img/uploads/customer/how-to/579/15531840725c93b5489d84e9.43781620.jpg`,
          leftSideText1: `${order.customer.firstName} ${order.customer.lastName}`,
          leftSideText2: `${order.shipping.address1}`,
          leftSideText3: `${order.shipping.postcode} ${order.shipping.city}`,
          leftSideText4: `${order.shipping.country}`,
          rightSideText1: `${order.orderNumber}`,
          rightSideText2: `${
            order.date
              ? dateFormat(order.date, "yyyy-mm-dd")
              : dateFormat(Date(), "yyyy-mm-dd")
          }`,
          rightSideText3: `${order.customer.customerId}`,
          data: data2, //get from DB or JSON or wherever you want
          footerHeader: `MELCHIOR SIMMA`,
          footerSection11: `GUT GEREIFT IM BREGENZERWALD`,
          footerSection12: `BANKVERBINDUNG: SPARKASSE EGG`,
          footerSection21: `WIESHALDE 381 • 6861 EGG • AUSTRIA`,
          footerSection22: `IBAN: AT22 2060 3000 0208 3681`,
          footerSection31: `T + 43 664 414 28 27`,
          footerSection32: `BIC: SPEGAT21XXX`,
          footerSection41: `INFO@GUTGEREIFT.AT`,
          footerSection42: `UID-NR. ATU75761604`,
          footerSection51: `WWW.GUTGEREIFT.AT`,
          footerSection52: `BEZIRKSGERICHT BEZAU`,
          sumField3: amountSum,
          sumField5: data2.total.toLocaleString("DE-DE", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          coolbox: coolboxPrice?.toLocaleString("DE-DE", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        });

        fetch("https://v2018.api2pdf.com/chrome/html", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "01da079e-48b4-463b-bce8-91910edb4917", //Get your API key from https://portal.api2pdf.com
          },
          body: JSON.stringify({
            html: htmlString,
            fileName: `Bestellung_${order.orderNumber}.pdf`,
            options: {
              inlinePdf: true,
              marginBottom: "2.5cm",
              displayHeaderFooter: true,
              footerTemplate:
                '<div class="page-footer" style="width:100%; text-align:center; font-size:6px; -webkit-print-color-adjust: exact;"><div style="display: flex;flex-flow:row; margin-left: 1cm; margin-right: 1cm; justify-content: space-between;font-family: Arial Narrow, sans-serif;"><div style="display: flex; flex-flow: row; justify-content: flex-start; align-items: start;" ><p style="text-align: left; ">MELCHIOR SIMMA<br><span style="color: #d7b436 !important;">GUT GEREIFT IM BREGENZERWALD</span><br>BANKVERBINDUNG: SPARKASSE EGG</p></div><div class="footer-box-2"><p style="text-align: left; "><br>WIESHALDE 381 • 6861 EGG • AUSTRIA<br>IBAN: AT22 2060 3000 0208 3681</p></div><div class="footer-box-3"><p style="text-align: left; "><br>T + 43 664 414 28 27<br>BIC: SPEGAT21XXX</p></div><div class="footer-box-4"><p style="text-align: left; "><br>INFO@GUTGEREIFT.AT<br>UID-NR. ATU75761604</p></div><div class="footer-box-5"><p style="text-align: left; "><span style="color: #d7b436 !important;""><br>WWW.GUTGEREIFT.AT</span><br>BEZIRKSGERICHT BEZAU</p></div></div>Seite <span class="pageNumber"></span> von <span class="totalPages"></span></div>',
              // "footerTemplate": footer
            },
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);
            // window.open(res.pdf)
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = res.pdf;
            a.download = res.pdf;
            a.click();
            document.body.removeChild(a);
          });
      },
      "text"
    );
  };

  return showButton && data2 && amountSum ? (
    <StyledButton
      onClick={async () => {
        setLoading(true);
        ExportOrder();
      }}
      scale={"ORDER"}
      title={"Bestellung herunterladen"}
      loading={loading}
      color={color ? color : colors.YellowBrigthButton}
      fontSize={"20px"}
    />
  ) : (
    <p>Loading</p>
  );
};

export default OrderComplete;
